<template>
  <div class="main" :style="mainHeight" id="expenses">
    <div class="wrap" :style="wrapHeight">
      <div class="top">
        <div class="currentMonthBox" @click="pickData()">
          <span class="left">
            <p>{{userDate | formatMonths}}</p>
            <p>
              <!-- <font>日均消费：{{user.userInfor.avgFee}}元；   当前余额：{{user.userInfor.balance}}元</font> -->
              <font v-if="$route.query.id">用能量：{{monthData.readings}}度；   用能金额：{{monthData.amount}}元</font>
              <font v-else>用能金额：{{monthData.amount}}元；   当前余额：{{monthData.balance}}元</font>
            </p>
          </span>
          <img :src="pickme">
        </div>
      </div>
      <van-popup
          v-model="showDatePick"
          closeable
          position="bottom"
          :style="{ height: '400px' }"
        >
        <van-datetime-picker v-if="showDatePick" class="datetime-picker"
          style="margin-bottom:80px"
          v-model="userDate"
          type="year-month"
          @confirm="changeData('confirm')"
          @cancel="changeData()"
        />
      </van-popup>
    </div>
    <div class="tab_header">
      <van-collapse v-model="activeName" accordion v-if="currentId === ''">
        <van-collapse-item v-for="(item, index) in dateLists" :key="index" :title="item.dataDate" :name="index" :value="`-${item.amount}`">
          <div v-for="(detail, key) in item.meterList" :key="key" class="meterList_box">
            <div v-if="detail.feeType === 'PERSONAL'" :class="'meterList_detail meterList_detail_short'" :style="`background:url(${expensesDatailBg}) no-repeat;background-size:100% auto`">
              <div class="top_header">
                <img :src="iconPower" />
                <span>{{detail.type | filterType}}：{{detail.name}}</span>
              </div>
              <ul class="box_content">
                <li>
                  <span class="left_span">用能量</span>
                  <span class="right_span">{{detail.readings}}</span>
                </li>
                <li>
                  <span class="left_span">用能金额</span>
                  <span class="black_font">-{{detail.amount}}</span>
                </li>
              </ul>
            </div>
            <div v-else-if="detail.feeType === 'PUBLIC'" :class="'meterList_detail meterList_detail_long'" :style="`background:url(${expensesDatailBgLong}) no-repeat;background-size:100% auto`">
              <div class="top_header">
                <img :src="publicIcon" />
                <span>公摊费用</span>
              </div>
              <ul class="box_content">
                <li>
                  <span class="left_span">公摊时间</span>
                  <span class="right_span">{{detail.publicTime}}</span>
                </li>
                <li>
                  <span class="left_span">公摊总金额（元）</span>
                  <span class="right_span">{{detail.publicAmountCount}}</span>
                </li>
                <li>
                  <span class="left_span">分摊比例</span>
                  <span class="right_span">{{detail.publicScale}}</span>
                </li>
                <li>
                  <span class="left_span">分摊金额（元）</span>
                  <span class="black_font">-{{detail.publicAmount}}</span>
                </li>
              </ul>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div class="div" v-for="(item, index) in dateLists" :key="index" v-show="currentId !== ''">
        <ul class="detail_ul">
            <li>
                <span class="left_span" style="margin-top:1rem">{{item.dataDate}}</span>
            </li>
            <li class="right_li">
                <span class="top_span">-{{item.amount}}(元)</span>
                <span class="bottom_span">用能量：{{item.readings}}{{item.meterType | filterMeterCostUnit}}
                </span>
            </li>
          </ul>
          <!-- <ul class="detail_ul" >
            <li class="top_li">
              <img :src="item.meterType | filterIcon"/>
            </li>
            <li>
                <span>{{item.meterType | filterType}}:{{item.meterName}}</span>
                <span>{{item.dataDate}}</span>
            </li>
            <li class="right_li">
                <span>-{{item.amount}}(元)</span>
                <span>用能量：{{item.readings}}{{item.meterType | filterMeterCostUnit}}
                </span>
            </li>
          </ul> -->
          <!-- <div v-if="(showActive == index) && (item.list.length)" class="detail"> -->
          <!-- <div v-for="(d, i) in item.list" :key="i" class="detail_list" :style="`background:url(${backgroundBg}) no-repeat;background-size:100% auto`">
              <ul>
                <li class="top">
                  <span>{{d.dataDate | clearYear}}</span>
                  <span>当前余额：<font>{{d.balance}}</font></span>
                </li>
                <li>
                  <span class="center_span">{{d.readings}}</span>
                  <span class="center_span">{{d.amount}}元</span>
                </li>
                <li>
                  <span>用能量（{{item.meterType | filterMeterCostUnit}}）</span>
                  <span>用能金额（元）</span>
              </li>
              </ul>
            </div>   -->
      </div>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import api from '@/api/index'
  const { getRechargesInfor, fetchData } = api
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { getWxRecharge } from '@/api/wxSdk'
  import { userDB } from '@/db'
  import backgroundBg from '@/assets/home/background_bg.png'
  import pickme from '@/assets/home/pickme.png'
  // 水
  import waterIcon from '@/assets/home/water-big_3x.png'
  // 电
  import powerIcon from '@/assets/home/electricity-big_3x.png'
  // 气
  import gasIcon from '@/assets/home/airbig_3x.png'
  import typelistIcon from '@/assets/home/typelist.png'
  import expensesDatailBg from '@/assets/home/expenses_datail_bg.png'
  import expensesDatailBgLong from '@/assets/home/expenses_datail_bg_long.png'
  import iconPower from '@/assets/home/dianbiao.png'
  import publicIcon from '@/assets/home/public_icon.png'

  export default {
    data() {
      return {
        userDate: new Date(),
        pickme,
        userName: '',
        translateClass: 'triangle_border_down triangle_border_up',
        mainHeight: `height:${window.innerHeight || document.documentElement.clientHeight}px;overflow:hidden`,
        wrapHeight: '8rem',
        userRemainder: '',
        companyName: '',
        activeName: 0,
        expensesDatailBg,
        expensesDatailBgLong,
        waterIcon,
        powerIcon,
        gasIcon,
        publicIcon,
        pageCount: 0,
        loading: false,
        finished: true,
        lists: [],
        dateLists: [],
        showActive: -1,
        monthList: [],
        currentList: [],
        backgroundBg,
        typelistIcon,
        showDatePick: false,
        meterTypeList: false,
        meterTypeObj: {},
        showMeterTypeList: false,
        typeList: [{ GAS: '气表' }, { POWER: '电表' }, { WATER: '水表' }],
        currentType: '',
        currentName: '',
        currentId: '',
        monthData: { 'amount': 0, 'readings': 0, 'balance': 0 },
        iconPower,
        meterSeletList: { GAS: [], POWER: [], WATER: [] }
      }
    },
    computed: {
      ...mapState(['user', 'global'])
    },
    filters: {
      formatMonths(val) {
        return `${val.getFullYear()}-${val.getMonth() + 1}`
      },
      filterIcon(status) {
        const statusMap = {
          GAS: gasIcon,
          POWER: powerIcon,
          WATER: waterIcon
        }
        return statusMap[status]
      },
      filterType(status) {
        const statusMap = {
          GAS: '气表',
          POWER: '电表',
          WATER: '水表'
        }
        return statusMap[status]
      },
      filterMeterCostUnit(status) {
        const statusMap = {
          GAS: '立方米',
          POWER: '度',
          WATER: '吨'
        }
        return statusMap[status]
      },
      clearTime(val) {
        if ((!!val) && (val.indexOf('T'))) {
          return val.split('T')[0]
        }
        return val
      },
      clearYear(val) {
        return val.split('-')[1] + '-' + val.split('-')[2]
      }
    },
    created() {
      const list = this.user.meterlist
    },
    mounted() {
      const date = `${this.global.currentYear}-${this.global.currentMonth}-${this.global.currentDay}`
      var url = `/running/fees/data/vip/meterMonthData/${date}`
      if (this.$route.query.id) {
        this.currentId = this.$route.query.id
        url = `/running/fees/data/meter/${this.currentId}/monthData/${date}`
      }
      this.gettlementDetails(url, true)

      // const date = `${data.getFullYear()}-${this.formatDays(data.getMonth() + 1)}-${this.formatDays(data.getDate())}`
      // this.gettlementDetails(`/running/fees/data/vip/monthData/${date}`, false)
    },
    methods: {
      pickData() {
        this.showDialog()
        this.showDatePick = true
      },
      showDialog() {

      },
      formatDays(val) {
        if (parseInt(val) < 10) {
          return `0${val}`
        } else {
          return val
        }
      },
      changeData(item = '1') {
        this.currentType = ''
        if (item === 'confirm') {
          const date = `${this.userDate.getFullYear()}-${this.formatDays(this.userDate.getMonth() + 1)}-${this.formatDays(this.userDate.getDate())}`
          var url = `/running/fees/data/vip/meterMonthData/${date}`
          if (this.$route.query.id) {
            this.currentId = this.$route.query.id
            url = `/running/fees/data/meter/${this.currentId}/monthData/${date}`
          }
          this.gettlementDetails(url, false)
        }
        this.showDatePick = false
        this.showDialog()
      },
      /*
        加法函数
        @parmas arg1(被加数字) arg2(被加数字)
        @return 返回计算结果
      */
      accAdd(arg1, arg2) {
        var r1, r2, m
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        } try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return (arg1 * m + arg2 * m) / m
      },
      formatDatelist(list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].meterList) {
            const meterList = list[i].meterList
            for (let j = 0; j < meterList.length; j++) {
              const longDesc = meterList[j].longDesc
              const feeType = meterList[j].feeType
              if (feeType === 'PUBLIC' && longDesc && longDesc.indexOf(';') > -1) {
                const longDescList = longDesc.split(';')
                longDescList.map(item => {
                  const arr = item.split(':')
                  if (arr[0].indexOf('公摊时间') > -1) {
                    list[i].meterList[j].publicTime = arr[1]
                  } else if (arr[0].indexOf('公摊总金额') > -1) {
                    list[i].meterList[j].publicAmountCount = arr[1]
                  } else if (arr[0].indexOf('分摊比例') > -1) {
                    list[i].meterList[j].publicScale = arr[1]
                  } else if (arr[0].indexOf('分摊金额') > -1) {
                    list[i].meterList[j].publicAmount = arr[1]
                  }
                })
              }
            }
          }
        }
        return list
      },
      /*
        接口获取结算明细数据
        @parmas url(接口地址) flag(触发的条件)
      */
      gettlementDetails(url, flag) {
        // 调取接口函数
        const _self = this
        fetchData({}, url, 'get').then(result => {
          let amount = 0
          let readings = 0
          const res = result.list.list ? result.list : result
          console.log(res.list.length, 'res.list')
          const userBalance = this.user.userInfor.balance
          for (let i = 0; i < res.list.length; i++) {
            amount = this.accAdd(amount, res.list[i].amount).toFixed(2)
            readings = this.accAdd(readings, parseFloat(res.list[i].readings || 0)).toFixed(2)
          }
          this.dateLists = this.formatDatelist(res.list)
          if (res.amount && res.balance) {
            this.monthData = { amount: res.amount, readings: res.readings, balance: res.balance || userBalance }
          } else {
            this.monthData = { amount, readings, balance: userBalance }
          }
          // if (this.currentId !== '') {
          //   this.currentName = this.currentId
          //   for (let i = 0; i < this.dateLists.length; i++) {
          //     if (this.dateLists[i].meterId === this.currentId) {
          //       this.dateLists[i].hideList = false
          //     } else {
          //       this.dateLists[i].hideList = true
          //     }
          //   }
          //   this.currentId = ''
          // }
        }).catch(err => {
          console.error('gettlementDetails', err)
          // this.monthData = { amount: 214141, readings: 2222 }
          // const list = [
          //   {
          //     'amount': 1825.78,
          //     'dataDate': '2018-08-01',
          //     'meterList': [
          //       {
          //         'dataDate': '2018-08-01',
          //         'readings': 224636.22,
          //         'amount': 1825.78,
          //         'name': '电表1',
          //         'type': 'POWER',
          //         'feeType': 'PERSONAL',
          //         'longDesc': ''
          //       },
          //       {
          //         'dataDate': '2018-08-01',
          //         'readings': 224636.22,
          //         'amount': 1825.78,
          //         'name': '电表1',
          //         'type': 'POWER',
          //         'feeType': 'PERSONAL',
          //         'longDesc': ''
          //       },
          //       {
          //         'dataDate': '2018-08-01',
          //         'readings': '',
          //         'amount': 1825.78,
          //         'name': '',
          //         'type': '',
          //         'feeType': 'PUBLIC',
          //         'longDesc': '公摊时间:2019-11-01至2019-11-10;公摊总金额(元):11000.00;分摊比例:25.81%;分摊金额(元):2839.00'
          //       },
          //       {
          //         'dataDate': '2018-08-01',
          //         'readings': 224636.22,
          //         'amount': 1825.78,
          //         'name': '电表1',
          //         'type': 'POWER',
          //         'feeType': 'PERSONAL',
          //         'longDesc': ''
          //       }
          //     ]
          //   },
          //   {
          //     'amount': 1825.78,
          //     'dataDate': '2018-08-02',
          //     'meterList': [
          //       {
          //         'dataDate': '2018-08-01',
          //         'readings': 224636.22,
          //         'amount': 1825.78,
          //         'name': '电表1',
          //         'type': 'POWER',
          //         'feeType': 'PERSONAL',
          //         'longDesc': ''
          //       }
          //     ]
          //   }
          // ]
          // this.dateLists = this.formatDatelist(list)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    background: #fff;
    position: relative;
  }
  .meter_type_List_box {
    background: #fff;
    ul {
      width: 100%;
      height: auto;
      overflow: hidden;
      padding-bottom: 2rem;
      li {
        height: auto;
        width: 90%;
        padding: 0 5%;
        height: auto;
        min-height: 3rem;
        line-height: 3rem;
        color: #666;
        float: left;
        .left_ico {
          height: 1.5rem;
          float: left;
          margin-right: 1rem;
        }
        .large_font {
          font-size: 1rem;
          color: #000;
          font-weight: 500;
        }
        .list_span {
          display: inline-block;
          width: 6rem;
          text-align: center;
          height: 2.6rem;
          line-height: 2.6rem;
          background: #e6e6e6;
          color: #666;
          font-size: 0.8rem;
        }
      }
      .list_li {
        div {
          display: inline-block;
          width: 30%;
          text-align: center;
          height: 2.6rem;
          line-height: 2.6rem;
          background: #e6e6e6;
          color: #666;
          font-size: 0.8rem;
          padding: 2px;
          float: left;
          border: none;
          margin-right: 2%;
          margin-top: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          .list {
            margin-top: 0;
            width: auto;
            overflow: auto;
            min-width: 6rem;
            height: auto;
          }
        }
        .name_span{
          width: 6rem;
        }
        .active {
          padding: 0;
          border: 2px solid #FC9090;
        }
      }
    }
  }

  .wrap{
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 5rem;
    overflow: hidden;
    .van-picker {
      position: absolute;
      z-index: 5;
      width: 100%;
      bottom: 0rem;
    }
    .top {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: auto;
      min-height: 2rem;
      line-height: 2rem;
      background: #fff;
      img {
        height: 2rem;
        float: right;
        margin-top: 0.8rem;
      }
      p {
        padding: 0 1rem;
        margin: 1rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .triangle_border_down{
          display: inline-block;
          width:0;
          height:0;
          border-width:0.4rem 0.3rem 0;
          border-style:solid;
          border-color:#333 transparent transparent;/*灰 透明 透明 */
          margin:0px 0.5rem;
          position:relative;
        }
        .triangle_border_up {
          transform:rotate(180deg);
        }
      }
    }
    .currentMonthBox {
      width: 90%;
      padding: 0.5rem 5%;
      height: 4rem;
      background: #f2f2f2;
      line-height: 2rem;
      .left {
        display: inline-block;
        width: 90%;
        p {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          color: #333;
          font {
            font-size: 0.8rem;
            color: #999;
          }
        }
      }
    }
  }
  .meterList_box {
    margin-top: 1rem;
    width:100%;
    height: auto;
    overflow: hidden;
    .meterList_detail_long {
      height: 10.5rem;
    }
    .meterList_detail_short {
      height: 6.5rem;
    }
    .meterList_detail {
      width:100%;
      .top_header {
        width:80%;
        margin: 0 10%;
        height: 2.2rem;
        line-height: 2.2rem;
        font-size: 0.9rem;
        color:#000;
        border-bottom: 1px dashed #e5e5e5;
        img {
          width: 1rem;
          float: left;
          margin-top: 0.6rem;
          margin-right: 0.6rem;
        }
      }
      .box_content {
        width:80%;
        margin: 0 10%;
        height: auto;
        line-height: 1.8rem;
        font-size: 0.9rem;
        padding-top: 0.4rem;
        li {
          width: 100%;
          float: left;
          .left_span {
            float: left;
            color:#999FAA;
          }
          .right_span {
            float: right;
            color:#999FAA;
          }
          .black_font {
            float: right;
            color:#000;
          }
        }
      }
    }
  }

  .meterList_box:first-child {
    margin-top: 0;
  }

  .detail {
    float: left;
    width: 100%;
    border-top: 1px solid #fff;
    padding-top: 0.4rem;
    background: #F5F6F8;
    padding-bottom: 1rem;
    div {
      float: left;
      width: 100%;
      height: 1.6rem;
      line-height: 1.6rem;
    }
    .detail_list {
      width: 80%;
      margin: 0 10%;
      margin-top: 1rem;
      height: auto;
      min-height: 9rem;
      overflow: hidden;
      line-height: 2.3rem;
      background: #fff;
      ul {
        li {
          span {
            display: inline-block;
            width: 49%;
            font-size: 0.8rem;
            color: #999;
          }
          .center_span {
            height: 3rem;
            line-height: 3rem;
            font-size: 1.2rem;
            color: #333;
          }
        }
        .top{
          span{
            padding: 0;
            text-align: right;
            padding-right: 4%;
            width: 65%;
            font {
              font-size: 1rem;
              color: #333;
            }
          }
          span:first-child {
            width: 25%;
            text-align: left;
            padding: 0;
            padding-left: 4%;
          }
        }
      }
    }
  }
  .tab_header {
    width:100%;
    float: left;
    font-size: 1rem;
    height: calc(100% - 9rem);
    overflow: auto;
    background: #fff;
    margin-top: 5rem;
    .div {
      text-align: center;
      height: auto;
      min-height: 3rem;
      line-height: 2.4rem;
      color: #333;
      background: #fff;
      width: 100%;
      float: left;
      padding:0;
      font-size: 1.1rem;
      border-bottom: 1px solid #e5e5e5;
      h2 {
        display: inline-block;
        font-weight: 400;
        font-size: 1.4rem;
        margin: 0;
        text-align: left;
        width: 90%;
        padding: 0 5%;
      }
      .left {
        width: 20%;
        float: left;
        font-size: 0.9rem;
      }
      .detail_ul {
        width: 90%;
        padding: 0.5rem 5%;
        height: 4rem;
        overflow: hidden;
        li {
          width: 50%;
          float: left;
          text-align: left;
          span {
            display: inline-block;
            float: left;
            width: 100%;
            height: 2rem;
            line-height: 2rem;
            overflow: hidden;
            font-size: 1rem;
            color: #333;
          }
        }
        .top_li {
          width: 10%;
          img {
            height: 1.8rem;
            margin-top: 1rem;
          }
        }
        .right_li {
          span {
            text-align: right;
          }
          .top_span {
            color:#000;
            font-size: 1.1rem;
          }
          .bottom_span {
            color:#999;
            font-size: 0.9rem;
          }
        }
      }
      .right{
        width: 10%;
        float: right;
      }
    }
  }

  .list_box {
    height: calc(100% - 100px);
    overflow: hidden;
    overflow: auto;
    p {
      text-align: center;
    }
  }
  .content_box {
    width:98%;
    margin: 0 1%;
    margin-top: 10px;
    height: 100%;
    overflow: auto;
    background: #fff;
    .top {
      border-left: 8px solid #4fb4e9;
      padding-left: 30px;
    }
    span {
      display: inline-block;
      width: 45%;
      float: left;
    }
    span:nth-child(3) {
      width: 10%;
    }
  }
</style>
